/* Header.css */
/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: 'black !important'; /* Ensure background is transparent */
    /* Add any other styles you need */
    color:'white';
  }
  
  
  .logo img {
    width: 50px; /* Adjust size as needed */
    height: auto;
  }
  
  .nav-links ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .nav-links ul li {
    display: inline-block;
    margin-left: 20px; /* Adjust spacing between links */
  }
  
  
.nav-links a {
    cursor:pointer;
}

a:hover{
    color:grey!important;
  }