@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body{
  font-family: "Montserrat", sans-serif;
}

#OS{
  background-color: black;
  min-height: 102vh;
  color:white;
  max-width:100%;
}

#EPK{
  background-color: black;
  min-height: 103vh;
  color:white;
  width:100%;
  padding: 20px;
}

#GENESIS{
  background-color: black;
  min-height: 103vh;
  color:white;
  width:100%;
}

div.card{
  padding:5px !important;
  background-color: rgb(160, 160, 160);
  color:white;
  background-color: #060606;
  border: 1px solid #73ecff;
  border-radius:15px;
  box-shadow: 0 3px 5px 2px rgba(115, 236, 255, 0.7);
  transition: transform 0.3s ease-in-out;
}

div.card:hover {
  transform: scale(1.02);
}

div.cardHeader{
  padding:5px !important;
}

.headings{
  text-align: center;
}

/* body{
  overflow: hidden;
} */

h1,
section,
p,
span,
svg{
  transition: background-color 0.5s, color 0.5s;
}

:root{
  /* Light Theme Variables */
  --color-text-light: #333332;
  --color-background-light: #FAF4E5;
  --color-pink-light: #4652d4;
  --color-green-light: #7ac5d0;
  --color-blue-light: #8395CD;

  /* Dark Theme Variables */
  --color-text-dark: #FAF4E5;
  --color-background-dark: #8395CD;
}

.light-theme{
  --color-text: var(--color-text-light);
  --color-background: var(--color-background-light);
  --color-pink: var(--color-pink-light);
  --color-green: var(--color-green-light);
  --color-blue: var(--color-blue-light);
}

.dark-theme{
  --color-text: var(--color-text-dark);
  --color-background: var(--color-background-dark);
  --color-pink: var(--color-text-dark);
  --color-green: var(--color-text-dark);
  --color-blue: var(--color-text-dark);
}

/* Experience --------------------------------------------- */

.experience{
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.experience-canvas{
  width: 100%;
  height: 100%;
}

.hidden{
  display: none;
}

/* Preloader */
.preloader{
  background-color: var(--color-background-light);
  width: 100%;
  height: 100vh;
  position: fixed;
  opacity: 1;
  z-index: 99999999999999999;
}

.preloader-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loading{
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 6px;
  background-color: var(--color-pink-light);
  animation: load 1s ease-in-out infinite;
}

.aboutAlbum{
  width:80%;
  margin:0 auto;
  text-align: start;
  padding-top:5%;
}

.aboutAlbum h2, .aboutNEW h2{
 margin:2% 0;
}

.aboutAlbum a, .aboutNEW a{
  color:white;

}

.aboutNEW{
  width:25%;
  margin:0 auto;
  text-align: center;
  padding-top:5%;
}


.circle:nth-child(2){
  animation-delay: 0.1s;
}

.circle:nth-child(3){
  animation-delay: 0.2s;
}

@keyframes load {
  0% {
    transform: translateY(0)
  }

  20% {
    transform: translateY(-12px)
  }

  40% {
    transform: translateY(0)
  }
  
}

/* Page Itself --------------------------------------------- */
.page{
  z-index:99999;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.page-wrapper{
  position: relative;
}

/* Preloader Stuff */
.animatedis{
  display: inline-block;
  transform: translateY(100%);
}


.arrow-svg-wrapper{
  position: absolute;
  top: 90%;
  left: 50%;
  opacity: 0;
  color: var(--color-text);
  transform: translate(-50%, -50%);
  animation: bounce 0.5s ease-in alternate infinite;
}

@keyframes bounce {
  0%{
    transform: translateY(0)
  }

  100%{
    transform: translateY(8px)
  }
}


.custom-carousel .slide .legend{
  width:auto !important;
}

button.legend{
  margin:auto !important;
  margin-left: -5% !important;
}


.slide{
  width:100% !important;
}

.igLogo{
  color:white;
}

 .theWavPack, .beatsandservices{
  margin-top: -10% !important;
}

/*GENESIS*/
#GENESIS {
  text-align: center;
}

.container {
  display: flex;
  justify-content: center;
}

#NEW{
  background-color: black;
  color:white;
}

.logo-button-container {
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content */
  margin-bottom: 10px; /* Adjust as needed */
}

.pre-save-button {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #E6EAF2;
  color: #2B2B35;
  font-weight: 600;
  cursor: pointer;
  margin-left: 10px; /* Add margin between logo and button */
}

/* Media Queries */

@media (max-width: 968px){

  #comingSoon{
    font-size:0.95em;
  }

  #GenesisImageDiv img{
    width:50%!important;
    max-width:50%!important;
  }

  #NEWImageDiv img{
    width:30%!important;
    max-width:30%!important;
  }

  .aboutNEW{
    width: 50%;
    font-size: 0.75em;
  }
  
  .linksContainer{
    width:65%!important;
    max-width:65%!important;
    margin: 0 auto;
    padding: 0;
  }

  .linksContainer p{
    font-size: 0.75em;
  }

  h1{
    font-size: 25px;
  }

  h4{
    font-size:14px;
  }

  p{
    font-size:0.8em;
  }


  .logoSize{
    width:25px;
  }

  .pre-save-button {
    padding: 6px 12px;
    font-size:0.6em;
  }

  #OS{
    padding-left: 3%;
  }

  #socials{
    padding-right:7%;
  }

  #NEWVideo{
    width:80%;
  }
  .section{
    width: 100%;
  }
  .progress-bar-wrapper-left{
    right: 0;
    left: auto;
  }
  .hero-main{
    bottom: 120px;
  }
  .hero-second{
    top: 160px;
  }
  .hero-wrapper{
    width: calc(100% - 60px);
  }
  .hero-main-title{
    font-size: 32px;
  }
  .hero-main-description{
    font-size: 18px;
  }
  .hero-second-subheading{
    font-size: 18px;
  }
}

@media (max-width: 600px){

  
  .linksContainer{
    width:70%!important;
    max-width:70%!important;
    margin: 0 auto;
    padding: 0;
  }

}
